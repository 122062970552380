import {Link} from "gatsby"
import styled from 'styled-components'
import React from 'react'

export const StyledLink = styled(Link)`
    display: inline-block;
    background-color: lime;
    min-width: 2.5em;
    height: 1em;
    padding: 0.5em 1.5em;
    margin:  0.25em 0.75em ;
    border-radius: 1em;
    border: solid 0.1em grey;
    :hover{ 
        background-color: limegreen;
    }
`

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    @media (min-width: 1200px) {
        justify-content: center;
  }
`

export const HeaderWrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: solid 1px aliceblue;
`

const StyledHomeLink = styled(Link)`
  font-size: 2em;
  font-family:'Courier New', Courier, monospace;
  justify-self: center;
  font-weight: 700;
  display: block;
  padding: 1em 0;
  text-align: center;
  color: black;
  cursor:pointer;  
  text-decoration-color: lime;
  :hover {
    color: lime;
    }
`
export const NameLink = () => <StyledHomeLink to="/">Iain Lumsden</StyledHomeLink>

export const Content = styled.div`
    align-items: flex-start;
    align-content: space-between;
    width: 100%;
    height: 100%;

    @media (min-width: 1200px) {
        width: 50%;
  }
`

export const PostLink = ({frontmatter}) => {
    return (
        <PostLinkWrapper>
            <PostLinkHeader> <StyledPostLink to={frontmatter.path}>
                {frontmatter.title}
            </StyledPostLink>
                <NiceText>{frontmatter.date}</NiceText>
            </PostLinkHeader>
            <NiceText>
                {frontmatter.excerpt}
            </NiceText>
        </PostLinkWrapper>
    )
}

const PostLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px grey;
`
export const PostLinkHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const NiceText = styled.p`
font-size: 1.1em;
margin:  1em 0;
font-family:'Courier New', Courier, monospace;
`


export const StyledPostLink = styled(Link)`
font-size: 1.3em;
font-family:'Courier New', Courier, monospace;
justify-self: flex-start;
color: black;
display: block;
cursor: pointer;
margin: 1em 0.25em; 
text-decoration-color: lime;
max-width: 60%;
:hover {
    color: lime;
  }
`

export const StyledTextArea = styled.div`
    font-family: 'Courier New', Courier, monospace;
    @media (min-width: 1200px) {
        margin: 3em;
  }
`;


export const IconStyles = {
    color: 'lime',
    margin: '0.5em'
}

export const Title = styled.h1`
  font-size: 2em;
  font-family:'Courier New', Courier, monospace;
  text-decoration:none;
  display: block;
  padding: 0;
  color: black;
`


export const StyledDiscription = styled.p`
    margin-top: 0;
    font-size: 0.8em;
    text-align: center;
`;

export const MyLayout = ({children}) => {
    return (
        <Container>
            <Content>
                {children}
            </Content>
        </Container>)
}

